
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import SEOHead from '../../components/SEOHead';
import thumbnailImage from '../../public/work/termomix/thumbnail.jpg';
import WorkVideo from '../../components/WorkVideo';
import ExploreAllWorks from '../../components/ExploreAllWorks';
import WorkParagraph from '../../components/WorkParagraph';
import WorkLink from '../../components/WorkLink';
import DefaultSiteContainer from '../../components/DefaultSiteContainer';
import { useWorkTranslation } from '../../hooks';
import Footer from '../../components/Footer';
import EndPageCta from '../../components/EndPageCta';
import WorkImageRow from '../../components/WorkImageRow';
// Images
import conceptImage from '../../public/work/termomix/conceptImage.webp';
import animated1 from '../../public/work/termomix/animated1.webp';
import storyboardImage from '../../public/work/termomix/storyboard.webp';
import styleframe1 from '../../public/work/termomix/styleframe1.webp';
import styleframe2 from '../../public/work/termomix/styleframe2.webp';
import styleframe3 from '../../public/work/termomix/styleframe3.webp';
import styleframe4 from '../../public/work/termomix/styleframe4.webp';
import styleframe5 from '../../public/work/termomix/styleframe5.webp';
import styleframe6 from '../../public/work/termomix/styleframe6.webp';
import styleframe7 from '../../public/work/termomix/styleframe7.webp';
import styleframe8 from '../../public/work/termomix/styleframe8.webp';
import styleframe9 from '../../public/work/termomix/styleframe9.webp';
import colorPalette from '../../public/work/termomix/colorPalette.png';
import macbookWebsite from '../../public/work/termomix/macbookWebsite.png';
const meta = {
    id: 'termomix',
    featured: false,
    date: '2023-12-5',
    thumbnail: {
        src: thumbnailImage,
        metaLink: '/work/termomix/thumbnail.jpg'
    },
    link: 'https://termomix.it?ref=anubi',
    copy: {
        en: {
            title: 'TermoMix',
            tags: '2D, Explainer, Website',
            description: 'TermoMix is a next-generation All-in-One system and collector Made in Italy. It manages low-temperature heating, high-temperature heating, and cooling systems.',
            challengeTitle: 'TermoMix',
            challengeDescription: 'TermoMix is a next-generation All-in-One system and collector Made in Italy. It manages low-temperature heating, high-temperature heating, and cooling systems. \nThe main challenge was the digitalization of a functional product, conveying its features and operation through a landing page and an explainer video that would be easy, intuitive, and applicable to both end-users and professionals.',
            conceptTitle: 'Concept',
            conceptDescription: '"Premium," "All-in-One," and "User-Friendly" were the key concepts of the brief upon which we relied during the exploration phase. \nWe designed the manifold using simple shapes with the aim of being easily recognizable and close to the real product.',
            styleframesTitle: 'Styleframes',
            styleframesDescription: 'Solid shapes, a simple color palette, and the use of gradients provide a modern and recognizable appearance. \nThe warm and cool tones in the color selection reflect the product\'s ability to handle various temperatures.',
            websiteTitle: 'Landing page',
            websiteDesciption: 'We designed and developed the TermoMix website featuring a modern layout, consistent with the explainer video, including various illustrated sections that reinforce the product\'s features and benefits. \nThe website aims to promote TermoMix and its usage.'
        },
        it: {
            title: 'TermoMix',
            tags: '2D, Explainer, Website',
            description: 'TermoMix è un collettore All-in-One di nuova generazione Made in Italy.\nGestisce impianti a bassa temperatura, ad alta temperatura e di raffrescamento.',
            challengeTitle: 'TermoMix',
            challengeDescription: 'TermoMix è un collettore All-in-One di nuova generazione Made in Italy. \nGestisce impianti a bassa temperatura, ad alta temperatura e di raffrescamento. \nLa challenge principale del progetto è stata la digitalizzazione di un prodotto funzionale trasmettendo le sue caratteristiche e il suo funzionamento attraverso una landing page ed un explainer video che potesse essere facile, intuitivo e trasversale sia agli utilizzatori che agli adetti ai lavori.',
            conceptTitle: 'Concept',
            conceptDescription: '"Premium", "All-in-One" e "di facile utilizzo" sono stati i concetti chiave del brief su cui ci siamo basati durante la fase di esplorazione. \nAbbiamo disegnato il collettore utilizzando forme semplici con l\'obiettivo di essere facilmente riconoscibile e fedele al prodotto reale.',
            styleframesTitle: 'Styleframes',
            styleframesDescription: 'Forme solide, una palette colori semplice e l\'utilizzo di gradient offrono un aspetto moderno e riconoscibile. \nLe tonalità calde e fredde nella scelta dei colori rispecchiano le peculiarità del prodotto nel gestire diverse temperature.',
            websiteTitle: 'Landing page',
            websiteDesciption: 'Abbiamo disegnato e sviluppato il sito web di TermoMix caratterizzato da un layout moderno, in linea con il video explainer, includendo diverse sezioni illustrate che rafforzano le caratteristiche e i vantaggi del prodotto. \nIl sito ha l\'obiettivo di promuovere TermoMix ed il suo utilizzo.'
        }
    }
};
export { meta };
export default function TermomixWorkPage() {
    const { t } = useWorkTranslation(meta);
    return (<>
      <SEOHead title={`Anubi | ${t('title')}`} description={t('description')} image={meta.thumbnail.metaLink}/>
      <DefaultSiteContainer>
        <WorkVideo ratio={[16, 9]} vimeoId='885680925'/>
        <WorkLink href={meta.link}/>
        <WorkParagraph title={t('challengeTitle')}>
          {t('challengeDescription')}
        </WorkParagraph>
        <WorkParagraph title={t('conceptTitle')}>
          {t('conceptDescription')}
        </WorkParagraph>
        <WorkImageRow images={[conceptImage]}/>
        <WorkImageRow images={[storyboardImage]}/>
        <WorkParagraph title={t('styleframesTitle')}>
          {t('styleframesDescription')}
        </WorkParagraph>
        <div style={{ height: '8px' }}/>
        <WorkImageRow images={[styleframe1, styleframe2, styleframe3]} quality={60}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[styleframe4, styleframe5, styleframe6]} quality={60}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[styleframe7, styleframe8, styleframe9]} quality={60}/>
        <WorkImageRow images={[colorPalette]}/>
        <br />
        <br />
        <WorkImageRow images={[animated1]}/>
        <br />
        <WorkParagraph title={t('websiteTitle')}>
          {t('websiteDesciption')}
        </WorkParagraph>
        <WorkLink href={meta.link}/>
        <div style={{ height: '12px' }}/>
        <WorkImageRow images={[macbookWebsite]} quality={70}/>
        <ExploreAllWorks />
      </DefaultSiteContainer>
      <EndPageCta />
      <Footer />
    </>);
}

    async function __Next_Translate__getStaticProps__18fe3cc0a9d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/work/termomix',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fe3cc0a9d__ as getStaticProps }
  